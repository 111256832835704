import * as React from "react";

import PageTitle from "../components/elements/PageTitle";
import Header from "../components/sections/Header";
import ContactsSection from "../components/sections/ContactsSection";
import Footer from "../components/sections/Footer";

const ContactsSePage = () => (
  <div className="bg-white">
    <PageTitle lang={"se"} pageTitle={"Kontakt"} description={""} />
    <Header lang={"se"} />
    <ContactsSection
      title={"Kontakta oss"}
      subtitle={""}
      formTitle={""}
      phone={"+46 (0)70 594 29 54"}
      email={"info@posplus.se"}
      firstnameLabel={"Ditt namn"}
      emailLabel={"e-post"}
      phoneLabel={"Telefonnummer"}
      subjectMail={"Request from Contacts"}
      submitButton={"Skicka in"}
      messageLabel={"Meddelande"}
      submitButtonSuccess={"Skickas"}
    />
    <Footer lang={"se"} />
  </div>
);

export default ContactsSePage;
